import baner from "./img/Main/banner/banner.jpg";
import logo from "./img/Main/Experiencias/AVIdesarrolladoralogo.png";
import icono1 from "./img/Main/Compromisodetrabajo/icono-1.png";
import icono2 from "./img/Main/Compromisodetrabajo/icono-2.png";
import icono3 from "./img/Main/Compromisodetrabajo/icono-3.png";
import imagen1 from "./img/Main/Compromisodetrabajo/foto-1.png";
import imagen2 from "./img/Main/Compromisodetrabajo/foto-2.png";
import bener2 from "./img/Main/Asesoria/banner-asesoria.jpg";
import benerayuda from "./img/Main/Comotepodemosayudar/banner.jpg";

import icon1 from "./img/Main/Iconos/icono-1.png";
import icon2 from "./img/Main/Iconos/icono-2.png";
import icon3 from "./img/Main/Iconos/icono-3.png";
import icon4 from "./img/Main/Iconos/icono-4.png";
import icon5 from "./img/Main/Iconos/icono-5.png";
import icon6 from "./img/Main/Iconos/icono-6.png";
import icon7 from "./img/Main/Iconos/icono-7.png";
import icon8 from "./img/Main/Iconos/icono-8.png";



import { motion } from "framer-motion";

const Main = () => {
  return (
    <motion.div
      initial={{ y: 0, opacity: 0.6 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container-fluid m-0 p-0">
        <div
          style={{
            backgroundImage: `url(${baner})`,
            minHeight: "700px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column "
        >
          <h1 className="titleMain mx-3">
            DESARROLLOS INMOBILIARIOS
            <br />
            ESPACIOS DE COWORKING
            <br />
            ASESORÍA Y GESTORIA INMOBILIARIA
          </h1>
        </div>
      </div>
      <div className="container-gris-50 d-flex justify-content-center">
        <div className="container  container-gris mx-3">
          <h2 className="px-2 py-3  mb-0">Una metodología de vanguardia</h2>
        </div>
      </div>

      <div className="container-fluid bg-white py-5">
        <div className="container mt-5 pt-3">
          <center>
            <img
              src={logo}
              className="img-fluid"
              style={{ maxWidth: "90px" }}
            />
          </center>

          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
        </div>

        <div className="container pb-5" style={{ maxWidth: "710px" }}>
          <h2 className="text-center CaviarDreams c-am mb-4">
            DESARROLLAMOS LAS MEJORES EXPERIENCIAS
          </h2>
          <p className="justify-last-center EuclidCircularALight">
          Creemos que la culminaciónm de nuestra pasión por construir desarrollos inmobiliarios con diseños de calidad, innovación y sustentabilidad produce comunidades y experiencias excepcionales para nuestros clientes.
          </p>
        </div>

        <div className="container-fluid bac-gray pt-5">
          <div className="container" style={{ maxWidth: "710px" }}>
            <h2 className="text-center CaviarDreams c-am">
              NUESTRO COMPROMISO DE TRABAJO
            </h2>
            <div className="d-flex justify-content-between my-4">
              <div className="lineGray align-self-center"></div>
              <div className="align-self-center">
                <div className="CircleGray"></div>
              </div>
              <div className="lineGray align-self-center"></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="w-100 d-flex justify-content-start mb-5 mt-0 mt-md-5 pt-3">
                  <div className="me-4">
                    <img
                      src={icono1}
                      className="img-fluid mt-1"
                      style={{ maxWidth: "35px" }}
                    />
                  </div>
                  <div>
                    <h2 className="CaviarDreams_Bold c-am">
                      Atención al cliente
                    </h2>
                    <p
                      className="EuclidCircularALight"
                      style={{ color: "#3d3d3d" }}
                    >
                      Trabajando contigo y tus ideas para crear cgrandes cosas
                      juntos.
                    </p>
                    <div className="lineParrafo" />
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-start mb-5">
                  <div className="me-4">
                    <img
                      src={icono2}
                      className="img-fluid mt-1"
                      style={{ maxWidth: "35px" }}
                    />
                  </div>
                  <div>
                    <h2 className="CaviarDreams_Bold c-am">
                    Innovación continua 
                    </h2>
                    <p
                      className="EuclidCircularALight"
                      style={{ color: "#3d3d3d" }}
                    >
                      Fomentamos una cultura de desarrollo y aprendizaje
                      constante.
                    </p>
                    <div className="lineParrafo" />
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-start mb-5">
                  <div className="me-4">
                    <img
                      src={icono3}
                      className="img-fluid mt-1"
                      style={{ maxWidth: "35px" }}
                    />
                  </div>
                  <div>
                    <h2 className="CaviarDreams_Bold c-am">Sintonía</h2>
                    <p
                      className="EuclidCircularALight"
                      style={{ color: "#3d3d3d" }}
                    >
                      Incentivamos la comunicación para crear conexiones
                      empresariales de valor.
                    </p>
                    <div className="lineParrafo" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div className="row">
                  <div className="col-6 mt-5 pt-3">
                    <img src={imagen1} className="img-fluid" />
                  </div>
                  <div className="col-6">
                    <img src={imagen2} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 py-3"></div>
        </div>
        {/* Fin de fluid */}
      </div>

      <div className="contaliner container-fluid p-0 m-0 bg-white">
        <div
          style={{
            backgroundImage: `url(${bener2})`,
            minHeight: "699px",
            backgroundPositionX: "center",
            backgroundPositionY: "top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column content-fondo-sm"
        >
          <h2
            className="titleMain px-2 titleMain-sm"
            style={{ fontSize: "calc(4rem + 1vw)" }}
          >
            ASESORÍA INTEGRAL
          </h2>
        </div>
        <div className="container-gris-50 d-flex justify-content-center">
          <div className="container  container-gris mx-3">
            <h2 className="px-2 py-3  mb-0">Servicios confiables y profesionales</h2>
          </div>
        </div>

        <div
          className="container pb-5 bg-white pt-5"
          style={{ maxWidth: "680px" }}
        >
          <h2 className="text-center CaviarDreams c-am mt-5 pt-5">
            DIRIGIENDO EL RUMBO INMOBILIARIO
          </h2>
          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
          <p className="justify-last-center EuclidCircularALight">
            Contamos con experiencia de más de 15 años, lo que nos lleva a dar
            una asesoría integral en las áreas financiera, legal, tecnica (
            gestoría en trámites y licencias), titulación y ventas.
          </p>
        </div>

        <div
          className="container-fluid d-flex justify-content-end content-fondo-sm"
          style={{
            backgroundImage: `url(${benerayuda})`,
            minHeight: "690px",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h2
            className="text-center CaviarDreams c-am align-self-center mx-2 me-2 me-md-5 "
            style={{ fontSize: "calc(2rem + 1vw)" }}
          >
            COMO TE PODEMOS
            <br />
            AYUDAR
          </h2>
        </div>
 
<div className="container-lg py-5">
      <div className="row bg-white mx-1 px-pd-4">
        <div className="col-6 col-md-3 d-flex justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon1}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Análisis financiero en un proyecto inicial 
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>
        <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon2}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Trámite y asesoría de crédito puente 
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon3}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
            Gestioría técnica
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon4}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Registro de oferente y conjunto para efectuar tu casa
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon5}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Apoyo en trámites legales 
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon6}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Venta de viviendas
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon7}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
          Titulación y recuperación de recursos
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>  <div className="col-6 col-md-3 d-flex  justify-content-start flex-column p-4 p-md-5">
          <img
            src={icon8}
            className="img-fluid mt-1 mx-auto"
            style={{ maxWidth: "55px" }}
          />
          <p className="justify-last-center CaviarDreams_Bold my-3 c-am text-center">
           Capacitación de créditos hipotecarios
          </p>
          <div className="flex-grow-1">

          </div>
          <a href="#" className="btn-Vermas mx-auto EuclidCircularALight">
           Ver más
          </a>
        </div>
      </div>
      </div>
      </div>

     
    </motion.div>
  );
};

export default Main;
