 
const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://avidesarrolladora.com/avi/"
    : "http://localhost/avi/";

export const ENTORNO_DE_PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;

// const BACKEND_URL = "https://tymtrackingapi.azurewebsites.net/api/"

export function postdData(url, body) {
  let bearer_token = "";
  return fetch(BACKEND_URL + url, {
    method: "POST",
    body: JSON.stringify(body), 
  })
    .then((response) => response.json())
    .then((response) => { 
      console.log(response)
        return { error: false, data: response }; 
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export function postUrl(url) {
  let bearer_token = "";
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export const getData = (url) => {
  let bearer_token = "";
  return (
    fetch(BACKEND_URL + url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    })
      /*
  .then(function(response) { 
    console.log(response);
    response.json()
    console.log(response);
    if (response.status === 401) {
      clearStorageJWT() 
    }  
  })
]*/
      .then((response) => {
        //console.log(response.status);
        if (response.status == 401) {
        
        }
        return response.json();
      })
      .then((response) => {
        console.log(response);
        return response.resp ;
      })
      .catch((error) => {
        //alert('response.status: ', error.status); // 👉️ 200
        // console.log(error);
        return { error: true, mensaje: error };
      })
  );
};
export default BACKEND_URL;
