import icono1 from "./../img/Main/piedepagina/icono-ubi.png";
import icono2 from "./../img/Main/piedepagina/icono-whats.png";
import icono3 from "./../img/Main/piedepagina/icono-msj.png";
import icono4 from "./../img/Main/piedepagina/icono-reloj.png";
import fondo from "./../img/Main/piedepagina/fondo.jpg";
import { useState } from "react";
import SendCorreoContacto from "../funciones/Contacto";

const FooterContacto = () => {
  const [values, setValues] = useState({
    nombre: "",
    correo: "",
    telefono: "",
    whats: "",
    mensaje: "",
    accion: "contacto1",
  });

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...values,
      [Name]: Value,
    };
    setValues(NewValue);
  };

  return (
    <>
      <div
        className="container-fluid bg-white"
        style={{
          backgroundImage: `url(${fondo})`,
          minHeight: "690px",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100" style={{ maxWidth: "1380px" }}>
          <div className="row h-100">
            <div
              className="col-12 col-md-5 py-5 h-100"
              style={{ backgroundColor: "#34455e" }}
            >
              <div className="w-100 d-flex justify-content-start mb-4 mt-5  px-2 px-md-5">
                <div className="me-4">
                  <img
                    src={icono1}
                    className="img-fluid mt-0"
                    style={{ maxWidth: "25px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold text-white">Ubicación:</h2>
                  <p
                    className="EuclidCircularALight text-white"
                    style={{ color: "#3d3d3d" }}
                  >
                    Herrera Cairo No. 342, colonia Centro, C.P 45500 San Pedro
                    Tlaquepaque
                  </p>
                </div>
              </div>

              <div className="w-100 d-flex justify-content-start mb-4 mt-2  px-2 px-md-5">
                <div className="me-4">
                  <img
                    src={icono2}
                    className="img-fluid mt-1"
                    style={{ maxWidth: "28px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold text-white">WhatsApp:</h2>
                  <p
                    className="EuclidCircularALight text-white"
                    style={{ color: "#3d3d3d" }}
                  >
                    <a
                      className="link"
                      href="https://wa.me/3340089108"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      (33) 4008 9108
                    </a>{" "}
                    <br />
                    <a
                      className="link"
                      href="https://wa.me/3312416880"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      (33) 12416880
                    </a>
                  </p>
                </div>
              </div>

              <div className="w-100 d-flex justify-content-start mb-4 mt-2  px-2 px-md-5">
                <div className="me-4">
                  <img
                    src={icono3}
                    className="img-fluid mt-1"
                    style={{ maxWidth: "28px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold text-white">
                    Correo eléctronico:
                  </h2>
                  <p
                    className="EuclidCircularALight text-white"
                    style={{ color: "#3d3d3d" }}
                  >
                    <a
                      className="link"
                      href="mailto:admin@avidesarrolladora.mx"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      admin@avidesarrolladora.mx
                    </a>
                    <br />
                    <a
                      className="link"
                      href="mailto:admin@avidesarrolladora.mx"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      dirección@avidesarrolladora.mx
                    </a>
                    <a
                      className="link"
                      href="mailto:admin@avidesarrolladora.mx"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <br />
                      ventas@avidesarrolladora.mx
                    </a>
                  </p>
                </div>
              </div>

              <div className="w-100 d-flex justify-content-start mb-5 mt-2  px-2 px-md-5">
                <div className="me-4">
                  <img
                    src={icono3}
                    className="img-fluid mt-1"
                    style={{ maxWidth: "28px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold text-white">Horario:</h2>
                  <p
                    className="EuclidCircularALight text-white"
                    style={{ color: "#3d3d3d" }}
                  >
                    Lunes a viernes de 9:00 am a 7:00pm.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 d-flex justify-content-center flex-column">
              <h3 className="title-amarillo CaviarDreams_Bold mx-2 ms-0 ms-md-5 mt-5 mt-ms-2">
                ¡Contactanos! Estamos apra atenderte
              </h3>

              <form>
                <div className="row my-1 my-md-5 px-2 ps-0 ps-md-5">
                  <div className="col-12 col-md-6 py-2">
                    <input
                      className="form-control input-contacto CaviarDreams"
                      placeholder="Nombre"
                      name="nombre"
                      value={values.nombre}
                      onChange={handlInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 py-2">
                    <input
                      className="form-control input-contacto CaviarDreams"
                      placeholder="Correo eléctronico"
                      name="correo"
                      value={values.correo}
                      onChange={handlInputChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 py-2">
                    <input
                      className="form-control input-contacto CaviarDreams"
                      placeholder="Teléfono"
                      name="telefono"
                      maxLength={10}
                      type="number"
                      value={values.telefono}
                      onChange={handlInputChange}
                    />
                  </div>

                  <div className="col-12 col-md-6 py-2">
                    <input
                      className="form-control input-contacto CaviarDreams"
                      placeholder="WhatsApp"
                      name="whats"
                      maxLength={10}
                      type="number"
                      value={values.whats}
                      onChange={handlInputChange}
                    />
                  </div>

                  <div className="col-12 py-2">
                    <textarea
                      className="form-control input-contacto CaviarDreams"
                      placeholder="Mensaje"
                      name="mensaje"
                      value={values.mensaje}
                      maxLength={1000}
                      onChange={handlInputChange}
                    />
                  </div>
                  <div className="col-12 ">
                    <div class="form-check my-3">
                      <label class="form-check-label text-white CaviarDreams_Bold">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="remember"
                        />
                        Estoy de acuerdo con el{" "}
                        <span>
                          <a href="#" className="text-white CaviarDreams_Bold">
                            Aviso de privacidad{" "}
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 text-end mb-5 mb-md-5 ">
                    <button
                      onClick={() => {
                        SendCorreoContacto(values);
                      }}
                      type="button"
                      class="btn btn-primary btn-100"
                      style={{ backgroundColor: "#34455e", border: "none" }}
                    >
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="my-0 py-4" style={{ background: "#535353" }}>
        <h5 className="text-center text-white m-0 CaviarDreams titleMain-xs">
          copyright © 2023 AVI Desarrollos Inmobiliarios
        </h5>
      </div>
    </>
  );
};

export default FooterContacto;
