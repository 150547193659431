import baner from "./img/Contacto/bannerprincipal/banner.jpg";
import fondo from "./img/Contacto/formulario/fondo.jpg";
import icono1 from "./img/Contacto/redessociales/face.png";
import icono3 from "./img/Contacto/redessociales/insta.png";
import icono2 from "./img/Contacto/redessociales/whats.png";

import { motion } from "framer-motion";
import { useState } from "react";
import SendCorreoContacto from "./funciones/Contacto";

const Contacto = () => {
  const [values, setValues] = useState({
    nombre: "",
    correo: "",
    whats: "",
    mensaje: "",
    accion: "contacto2",
  });

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...values,
      [Name]: Value,
    };
    setValues(NewValue);
  };

  return (
    <motion.div
      initial={{ y: 0, opacity: 0.6 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container-fluid m-0 p-0">
        <div
          style={{
            backgroundImage: `url(${baner})`,
            minHeight: "700px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column"
        >
          <h1 className="titleMain">CONTÁCTANOS</h1>
        </div>
      </div>
      <div className="container-gris-50 d-flex justify-content-center">
        <div className="container  container-gris mx-3">
          <h2 className="px-2 py-3  mb-0">Tenemos la mejor solución para ti</h2>
        </div>
      </div>
      <div className="w-100 py-5 bg-white" />
      <div className="w-100 py-5 bg-white" />

      <div
        className="container-fluid "
        style={{
          backgroundImage: `url(${fondo})`,
          minHeight: "690px",
          backgroundPositionX: "left",
          backgroundPositionY: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="row" style={{ minHeight: "690px" }}>
          <div className="col-12 col-md-6"></div>
          <div
            className="col-12 col-md-6 d-flex h-100 justify-content-center"
            style={{ minHeight: "690px" }}
          >
            <form className="align-self-center">
              <div className="row">
                <div className="col-12">
                  <div className="group">
                    <input
                      className="CaviarDreams input-contacto2"
                      type="text" 
                      required=""
                      name="nombre"
                      maxLength={300} 
                      value={values.nombre}
                      onChange={handlInputChange}
                    />
                    <span className="highlight"></span>{" "}
                    <span className="bar"></span>
                    <label className="labelAndroid CaviarDreams">Nombre:</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="group">
                    <input
                      className="CaviarDreams input-contacto2" 
                      required=""
                      name="correo"
                      maxLength={200}
                      type="text"
                      value={values.correo}
                      onChange={handlInputChange}
                    />
                    <span className="highlight"></span>{" "}
                    <span className="bar"></span>
                    <label className="labelAndroid CaviarDreams">Correo:</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="group">
                    <input
                      className="CaviarDreams input-contacto2" 
                      required=""
                      name="whats"
                      maxLength={10}
                      type="number"
                      value={values.whats}
                      onChange={handlInputChange}
                    />
                    <span className="highlight"></span>{" "}
                    <span className="bar"></span>
                    <label className="labelAndroid CaviarDreams">
                      WhatsApp:
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="group">
                    <input
                      className="CaviarDreams input-contacto2" 
                      required=""
                      name="mensaje"
                      maxLength={1000}
                      type="text"
                      value={values.mensaje}
                      onChange={handlInputChange}
                    />
                    <span className="highlight"></span>{" "}
                    <span className="bar"></span>
                    <label className="labelAndroid CaviarDreams">
                      Mensaje:
                    </label>
                  </div>
                </div>

                <div className="col-12 text-center">
                  <button   onClick={() => {
                        SendCorreoContacto(values);
                      }} 
                      type="button" className="btn-enviar CaviarDreams">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center bg-white py-5">
        <div className="px-2 py-5">
          <img
            src={icono2}
            className="img-fluid mt-1 "
            style={{ maxWidth: "40px" }}
          />
        </div>
        <div className="px-2 py-5">
          <img
            src={icono1}
            className="img-fluid mt-1 "
            style={{ maxWidth: "40px" }}
          />
        </div>
        <div className="px-2 py-5">
          <img
            src={icono3}
            className="img-fluid mt-1 "
            style={{ maxWidth: "41px" }}
          />
        </div>
      </div>

      <div className="bg-white container-fluid pb-5">
        <div className="container-lg pb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.9239513051803!2d-103.31320658507396!3d20.63195638621458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3ad151616c7%3A0x7cc2ad697d909c16!2sC.%20Herrera%20y%20Cairo%20342%2C%20La%20Capacha%2C%2045520%20San%20Pedro%20Tlaquepaque%2C%20Jal.!5e0!3m2!1ses!2smx!4v1677469164355!5m2!1ses!2smx"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Contacto;
