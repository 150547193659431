import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import img1 from "./../img/Proyectos/Galeriaproyectos/foto-1.png"
import img2 from "./../img/Proyectos/Galeriaproyectos/foto-2.png"
import img3 from "./../img/Proyectos/Galeriaproyectos/foto-3.png"
import img4 from "./../img/Proyectos/Galeriaproyectos/foto-4.png"
import img5 from "./../img/Proyectos/Galeriaproyectos/foto-5.png"
 
import { config } from "react-spring";

const getTouches = (evt) => {
  return (
    evt.touches || evt.originalEvent.touches // browser API
  );
};

const Carrusel3d =()=> {


const [state , setState_] = useState({
  goToSlide: 0,
  offsetRadius: 10,
  showNavigation: true,
  enableSwipe: true,
  config: config.gentle
})

 

  let slides = [
    {
      key: 12323,
      content: <img src={img1} alt="1" />
    },
    {
      key: 34345,
      content: <img src={img2} alt="2" />
    },
    {
      key: 123345456423,
      content: <img src={img3} alt="3" />
    },
    {
      key: 1232453233,
      content: <img src={img4} alt="4" />
    },
    {
      key: 122342323,
      content: <img src={img5} alt="5" />
    } 
  ].map((slide, index) => {
    return { ...slide, onClick: () => setState_({ goToSlide: index }) };
  });

const onChangeInput = (e) => {
    setState_({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  const handleTouchStart = (evt) => {
    if (!state.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    setState_({
      ...state,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY
    });
  };

  const  handleTouchMove = (evt) => {
    if (!state.enableSwipe || (!state.xDown && !state.yDown)) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = state.xDown - xUp;
    let yDiff = state.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        setState_({
          goToSlide: state.goToSlide + 1,
          xDown: null,
          yDown: null
        });
      } else {
        /* right swipe */
        setState_({
          goToSlide: state.goToSlide - 1,
          xDown: null,
          yDown: null
        });
      }
    }
  };
 
    return (
      <div
        style={{ width: "80%", height: "500px", margin: "0 auto" }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <Carousel
          slides={slides}
          goToSlide={state.goToSlide}
          offsetRadius={state.offsetRadius}
          showNavigation={state.showNavigation}
          animationConfig={state.config}
        />
        <div
          style={{
            margin: "0 auto",
            marginTop: "2rem",
            width: "50%",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          
        </div>
      </div>
    );
 
}
export default Carrusel3d