import baner from "./img/Proyectos/Bannerprincipal/banner.jpg";
import baner2 from "./img/Proyectos/Estandardecalidad/banner.jpg";
import logo from "./img/Main/Experiencias/AVIdesarrolladoralogo.png";
import fondo1 from "./img/Proyectos/soluciones/fondo.jpg";
import chevron from "./img/Proyectos/chevron.png";
import Carrusel3d from "./includes/Carrusel3d";

import { motion } from "framer-motion";

const Proyectos = () => {
  return (
    <motion.div
      initial={{ y: 0, opacity: 0.6 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container-fluid m-0 p-0">
        <div
          style={{
            backgroundImage: `url(${baner})`,
            minHeight: "700px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column"
        >
          <h1 className="titleMain">ALIANZAS ESTRATÉGICAS</h1>
        </div>
      </div>
      <div className="container-gris-50 d-flex justify-content-center">
        <div className="container  container-gris mx-3">
          <h2 className="px-2 py-3  mb-0">Desarrollamos relaciones sólidas</h2>
        </div>
      </div>
      <div className="w-100 py-5 bg-white" />
      <div className="container-fluid bg-white pb-5">
        <div className="container mt-0 pt-1">
          <center>
            <img
              src={logo}
              className="img-fluid"
              style={{ maxWidth: "90px" }}
            />
          </center>

          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
        </div>

        <div className="container pb-5" style={{ maxWidth: "710px" }}>
          <h2 className="text-center CaviarDreams c-am mb-4">
            GESTIONAMOS TUS PROYECTOS
            <br />
            INMOBILIARIOS DE PRINCIPOP A FIN
          </h2>
          <p className="justify-last-center EuclidCircularALight">
            Contamos con la experiencias para llevar a cabo sus proyectos desde
            su concepción hasta su liberación, coordinamos cada una de las
            etapas para asegurar un proyecto eficiente.
          </p>
        </div>
      </div>

      <div
        className="container-fluid d-flex justify-content-end content-fondo-sm"
        style={{
          backgroundImage: `url(${fondo1})`,
          minHeight: "600px",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="align-self-center mx-2 me-2 me-md-5 pe-0 pe-md-5 ">
          <h2
            className="text-star CaviarDreams c-am "
            style={{ fontSize: "calc(2rem + 1vw)" }}
          >
            SOLUCIONES
          </h2>

          <div className="d-flex justify-content-star align-items-center">
            <div>
              <img
                src={chevron}
                className="img-fluid mt-0 mx-auto"
                style={{ maxWidth: "25px" }}
              />
            </div>
            <p className="justify-last-center CaviarDreams_Bold my-2 c-am text-center">
              Gerencia de proyectos
            </p>
          </div>
          <div className="d-flex justify-content-star align-items-center">
            <div>
              <img
                src={chevron}
                className="img-fluid mt-0 mx-auto"
                style={{ maxWidth: "25px" }}
              />
            </div>
            <p className="justify-last-center CaviarDreams_Bold my-2 c-am text-center">
              Planeación y costeo
            </p>
          </div>
          <div className="d-flex justify-content-star align-items-center">
            <div>
              <img
                src={chevron}
                className="img-fluid mt-0 mx-auto"
                style={{ maxWidth: "25px" }}
              />
            </div>
            <p className="justify-last-center CaviarDreams_Bold my-2 c-am text-center">
              Asesoría, planeación estratégica
            </p>
          </div>
          <div className="d-flex justify-content-star align-items-center">
            <div>
              <img
                src={chevron}
                className="img-fluid mt-0 mx-auto"
                style={{ maxWidth: "25px" }}
              />
            </div>
            <p className="justify-last-center CaviarDreams_Bold my-2 c-am text-center">
              Financiamiento, gestión y trámites
            </p>
          </div>
        </div>
      </div>

      <div className="py-5 bg-white" style={{}}>
        <div className="w-100 py-5 bg-white" />
        <Carrusel3d />
        <div className="w-100 py-5 bg-white" />
      </div>

      <div className="contaliner container-fluid p-0 m-0 bg-white ">
        <div
          style={{
            backgroundImage: `url(${baner2})`,
            minHeight: "578px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column content-fondo-sm"
        >
          <h2
            className="titleMain px-2 titleMain-sm mx-3"
            style={{ fontSize: "calc(3rem + 1vw)" }}
          >
            ESTÁNDAR DE CALIDAD
            <br />
            EN NUESTROS PROYECTOS
          </h2>
        </div>
        <div className="my-0 py-5"></div>
      </div>
    </motion.div>
  );
};

export default Proyectos;
