import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/Main/banner/AVIdesarrolladoralogo.png";
import iconowhats from "../img/Main/banner/icono-whats.png";
import iconomsj from "../img/Main/banner/icono-msj.png";
import MenuIcon from "@mui/icons-material/Menu";
import Offcanvas from "react-bootstrap/Offcanvas";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

const Head = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="w-100 position-absolute " style={{ zIndex: 99 }}>
        <div className="container-lg d-flex justify-content-between align-items-center mt-2 px-3">
          <div>
          <Link
                to="/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
            <img
              src={logo}
              style={{ maxWidth: "50px" }}
              className="img-fluid "
            />
            </Link>
          </div>
          <div className="d-none d-md-block">
            <ul class="nav justify-content-center align-self-center">
              <Link
                to="/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
                <li class="nav-item ">
                  <p className="text-menu font-Avenir">
                    Inmobiliaria
                    <br />y gestioría
                  </p>
                </li>
              </Link>
              <Link
                to="nosotros/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
                <li class="nav-item">
                  <p className="text-menu">Nosotros</p>
                </li>
              </Link>
              <Link
                to="coworking/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
                <li class="nav-item">
                  <p className="text-menu">Coworking</p>
                </li>
              </Link>
              <Link
                to="proyectos/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
                <li class="nav-item">
                  <p className="text-menu">
                    Administración
                    <br />
                    de proyectos
                  </p>
                </li>
              </Link>
              <Link
                to="contacto/"
                style={{ textDecoration: "none", color: "white" }}
                className="p-2 px-4 align-self-center"
              >
                <li class="nav-item">
                  <p className="text-menu">Contacto</p>
                </li>
              </Link>
            </ul>
          </div>
          <div className="d-flex ">
            <div  >
              <a href="https://wa.me/3340089108" target={"_blank"}>
               <IconButton className="d-none2" aria-label="menu">
                <img
                  src={iconowhats}
                  style={{ width: "18px" }}
                  className="img-fluid"
                />
              </IconButton>
              </a>
             
            </div>
            <div>  
              <a href="mailto:admin@avidesarrolladora.mx" target={"_blank"}>
              <IconButton className="d-none2" aria-label="menu">
                <img
                  src={iconomsj}
                  style={{ width: "21px" }}
                  className="img-fluid"
                />
              </IconButton>
              </a>
            </div>

            <IconButton
              className="d-md-none d-block text-white"
              aria-label="menu"
              onClick={handleShow}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div>
        <>
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton={false}>
              <Offcanvas.Title>Menú</Offcanvas.Title>

              <IconButton onClick={() => setShow(false)}>
                <CloseIcon />
              </IconButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Link
                onClick={() => setShow(false)}
                to="/"
                style={{ textDecoration: "none", color: "#666666" }}
                className="align-self-center"
              >
                <Button fullWidth onClick={() => setShow(false)}>
                  <div className=" py-3 ps-0 d-flex justify-content-between w-100">
                    <p
                      className="text-menu CaviarDreams_Bold"
                      style={{ textDecoration: "none", color: "#666666" }}
                    >
                      Inmobiliaria y gestioría
                    </p>

                    <ArrowForwardIosIcon
                      style={{ textDecoration: "none", color: "#666666" }}
                    />
                  </div>
                </Button>
              </Link>
              <Link
                onClick={() => setShow(false)}
                to="/nosotros"
                style={{ textDecoration: "none", color: "#666666" }}
                className="align-self-center"
              >
                <Button fullWidth onClick={() => setShow(false)}>
                  <div className=" py-3 ps-0 d-flex justify-content-between w-100">
                    <p
                      className="text-menu CaviarDreams_Bold"
                      style={{ textDecoration: "none", color: "#666666" }}
                    >
                      Nosotros
                    </p>

                    <ArrowForwardIosIcon
                      style={{ textDecoration: "none", color: "#666666" }}
                    />
                  </div>
                </Button>
              </Link>
              <Link
                onClick={() => setShow(false)}
                to="/coworking"
                style={{ textDecoration: "none", color: "#666666" }}
                className="align-self-center"
              >
                <Button fullWidth onClick={() => setShow(false)}>
                  <div className=" py-3 ps-0 d-flex justify-content-between w-100">
                    <p
                      className="text-menu CaviarDreams_Bold"
                      style={{ textDecoration: "none", color: "#666666" }}
                    >
                      Coworking
                    </p>

                    <ArrowForwardIosIcon
                      style={{ textDecoration: "none", color: "#666666" }}
                    />
                  </div>
                </Button>
              </Link>
              <Link
                onClick={() => setShow(false)}
                to="/proyectos"
                style={{ textDecoration: "none", color: "#666666" }}
                className="align-self-center"
              >
                <Button fullWidth onClick={() => setShow(false)}>
                  <div className=" py-3 ps-0 d-flex justify-content-between w-100">
                    <p
                      className="text-menu CaviarDreams_Bold"
                      style={{ textDecoration: "none", color: "#666666" }}
                    >
                      Administración de proyectos
                    </p>

                    <ArrowForwardIosIcon
                      style={{ textDecoration: "none", color: "#666666" }}
                    />
                  </div>
                </Button>
              </Link>
              <Link
                onClick={() => setShow(false)}
                to="/contacto"
                style={{ textDecoration: "none", color: "#666666" }}
                className="align-self-center"
              >
                <Button fullWidth onClick={() => setShow(false)}>
                  <div className=" py-3 ps-0 d-flex justify-content-between w-100">
                    <p
                      className="text-menu CaviarDreams_Bold"
                      style={{ textDecoration: "none", color: "#666666" }}
                    >
                      Contacto
                    </p>

                    <ArrowForwardIosIcon
                      style={{ textDecoration: "none", color: "#666666" }}
                    />
                  </div>
                </Button>
              </Link>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      </div>
    </>
  );
};

export default Head;
