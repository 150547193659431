import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import foto1 from "./../img/coworking/galeria/foto-1.png";
import foto2 from "./../img/coworking/galeria/foto-2.png";

const Carrusel = () => {


  const [ancho, setAncho] = useState(window.innerWidth);

  useEffect(() => {
    function actualizarAncho() {
      setAncho(window.innerWidth);
    }

    window.addEventListener('resize', actualizarAncho);

    return () => window.removeEventListener('resize', actualizarAncho);
  }, []);

  return (
    <Splide 
      options={
        {
          type      : 'loop',
          perPage   : ancho >700 ? 2:1,
          perMove   : 1, 
          pagination: false, 
        }
      }
      aria-label="My Favorite Images"
    >
      <SplideSlide> 
          <div className="w-1000"  
           style={{
            backgroundImage: `url(${foto1})`,
            minHeight: "500px",
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          />  
      </SplideSlide>
      <SplideSlide>
      <div className="w-1000"  
           style={{
            backgroundImage: `url(${foto2})`,
            minHeight: "500px",
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          /> 
      </SplideSlide>
      <SplideSlide>
      <div className="w-1000"  
           style={{
            backgroundImage: `url(${foto1})`,
            minHeight: "500px",
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          /> 
      </SplideSlide>
    </Splide>
  );
};

export default Carrusel;
