import baner from "./img/coworking/bannerprincipal/banner.jpg";
import bannerplanes from "./img/coworking/bannerplanes/banner_coworking.jpg";
import lugarparatrabajar from "./img/coworking/lugarparatrabajar/lugarparatrabajar.png";
import fondo from "./img/coworking/Inspiracion/fondo.jpg";

import icon1 from "./img/coworking/Inspiracion/icono-1.png";
import icon2 from "./img/coworking/Inspiracion/icono-2.png";
import icon3 from "./img/coworking/Inspiracion/icono-3.png";

import servicio1 from "./img/coworking/nuestrosservicios/foto-1.png";
import servicio2 from "./img/coworking/nuestrosservicios/foto-2.png";
import servicio3 from "./img/coworking/nuestrosservicios/foto-3.png";

import { motion } from "framer-motion";
import Carrusel from "./includes/Carrusel";

const CoWorking = () => {
  return (
    <motion.div
      initial={{ y: 0, opacity: 0.6 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container-fluid m-0 p-0">
        <div
          style={{
            minHeight: "700px",
          }}
          className="d-flex justify-content-center flex-column"
        >
          <div
            id="carouselExampleInterval"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>

            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                <div
                  style={{
                    backgroundImage: `url(${baner})`,
                    minHeight: "700px",
                    backgroundPositionX: "center",
                    backgroundPositionY: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="d-flex justify-content-center flex-column"
                >
                  <div>
<h1 className="titleMain mx-5"  >
                    TRANSFORMANDO EL FUTURO DE LOS EMPRENDEDORES
                  </h1>
                  </div>
                  
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                <div
                  style={{
                    backgroundImage: `url(${baner})`,
                    minHeight: "700px",
                    backgroundPositionX: "center",
                    backgroundPositionY: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="d-flex justify-content-center flex-column"
                >
                  <h1 className="titleMain">BANNER 2</h1>
                </div>
              </div>
              <div class="carousel-item">
                <div
                  style={{
                    backgroundImage: `url(${baner})`,
                    minHeight: "700px",
                    backgroundPositionX: "center",
                    backgroundPositionY: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="d-flex justify-content-center flex-column"
                >
                  <h1 className="titleMain">BANNER 3</h1>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white py-5">
        <div className="container mt-5 pt-3">
          <center>
            <img
              src={lugarparatrabajar}
              className="img-fluid"
              style={{ maxWidth: "190px" }}
            />
          </center>

          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
        </div>

        <div className="container pb-5" style={{ maxWidth: "710px" }}>
          <h2 className="text-center CaviarDreams c-am">
            MÁS QUE UN LUGAR PARA TRABAJAR
          </h2>
          <p className="justify-last-center EuclidCircularALight">
            Nuestras soluciones de lugares de trabajo apoyan a las empresas de
            todos los tamaños, ayudándote a crear relaciones laborales que
            impulsen a tu negocio hacia el éxito.
          </p>
        </div>

        <div className="container d-flex justify-content-center flex-column flex-md-row">
          <div className="p-2">
            <button className="bnt-verder mx-0 mx-md-4">Descargar Brochure</button>
          </div>
          <div className="p-2">
            <button className="bnt-verder mx-09 mx-md-4">Visitar sitio web</button>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 bg-white">
        <div className="row px-0 mx-0">
          <div
            className="col-12 col-md-6"
            style={{
              backgroundImage: `url(${fondo})`,
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "200px",
            }}
          ></div>
          <div className="col-12 col-md-6 pt-5 d-flex justify-content-center  ">
            <div>
              <h2 className="text-center CaviarDreams c-am mt-3 w-100">
                INSPIRACIÓN PARA
                <br />
                TRABAJAR
              </h2>
              <div
                className="w-100 d-flex justify-content-start mb-5 mt-5 pt-3 ps-4"
                style={{ maxWidth: "386px" }}
              >
                <div className="me-4">
                  <img
                    src={icon1}
                    className="img-fluid mt-0"
                    style={{ maxWidth: "35px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold c-am">Capacitación</h2>
                  <p
                    className="EuclidCircularALight"
                    style={{ color: "#3d3d3d" }}
                  >
                    Queremos enseñarte todo, para que puedas seguir creciendo.
                  </p>
                  <div className="lineParrafo" />
                </div>
              </div>

              <div
                className="w-100 d-flex justify-content-start mb-5 mt-5 pt-3 ps-4"
                style={{ maxWidth: "386px" }}
              >
                <div className="me-4">
                  <img
                    src={icon2}
                    className="img-fluid mt-0"
                    style={{ maxWidth: "35px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold c-am">Comunidades</h2>
                  <p
                    className="EuclidCircularALight"
                    style={{ color: "#3d3d3d" }}
                  >
                    En nuestras comunidades podrás encontrar a más personas con
                    los mismos objetivos que tú.
                  </p>
                  <div className="lineParrafo" />
                </div>
              </div>

              <div
                className="w-100 d-flex justify-content-start mb-5 mt-5 pt-3 ps-4"
                style={{ maxWidth: "386px" }}
              >
                <div className="me-4">
                  <img
                    src={icon3}
                    className="img-fluid mt-0"
                    style={{ maxWidth: "35px" }}
                  />
                </div>
                <div>
                  <h2 className="CaviarDreams_Bold c-am">Eventos</h2>
                  <p
                    className="EuclidCircularALight"
                    style={{ color: "#3d3d3d" }}
                  >
                    Te brindaremos herramientas para hacer crecer tu negocio.
                  </p>
                  <div className="lineParrafo" />
                </div>
              </div>
            </div>
          </div>
        </div>
<div style={{backgroundColor:"#f7f5f6" }}> 
        <div className="container py-5" style={{ maxWidth: "710px", backgroundColor:"#f7f5f6" }}>
          <h2 className="text-center CaviarDreams c-am">NUESTROS SERVICIOS</h2>
          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
        </div>

        <div className="container-fluid  px-0 px-md-5 pb-5 m-0 ">
          <div className="row px-0 px-md-5 m-0">
            <div className="col-12 col-md-4 px-0 px-4">
              <div
                className="w-100 p-3 "
                style={{
                  height: "593px",
                  backgroundImage: `url(${servicio1})`,
                  backgroundPositionX: "center",
                  backgroundPositionY: "bottom",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <h2 className="text-center CaviarDreams c-am my-4">
                OFICINAS PRIVADAS
              </h2>
              <p
                className="EuclidCircularALight text-justitfy"
                style={{ color: "#3d3d3d", fontSize:"20px" }}
              >
                Establezca una base para su empresa con una oficina privada en
                uno de nuestros distritos financieros. Nuestras oficinas
                equipadas tienen todo bajo control: desde el mobiliario hasta
                wifi de alta velocidad.{" "}
              </p>
            </div>
            <div className="col-12 col-md-4 px-1 px-4 ">
              <div
                className="w-100 p-3 "
                style={{
                  height: "593px",
                  backgroundImage: `url(${servicio2})`,
                  backgroundPositionX: "center",
                  backgroundPositionY: "bottom",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <h2 className="text-center CaviarDreams c-am my-4">
                SALA DE JUNTAS
              </h2>
              <p
                className="EuclidCircularALight text-justitfy"
                style={{ color: "#3d3d3d", fontSize:"20px" }}
              >
                Impresione a sus clientes, dirija talleres memorables o un gran
                discurso en nuestras salas de juntas totalmente equipadas.
              </p>
            </div>
            <div className="col-12 col-md-4 px-1 px-4">
              <div
                className="w-100 p-3 "
                style={{
                  height: "593px",
                  backgroundImage: `url(${servicio3})`,
                  backgroundPositionX: "center",
                  backgroundPositionY: "bottom",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <h2 className="text-center CaviarDreams c-am my-4">COWORKING</h2>
              <p
                className="EuclidCircularALight text-justitfy"
                style={{ color: "#3d3d3d", fontSize:"20px" }}
              >
                Nuestros espacios de coworking están pensados con un diseño de
                colaboración para que trabaje junto a una comunidad de ideas
                afines en nuestra oficina compartida.
              </p>
            </div>
          </div>
        
        </div>
        </div>
        


        <div
          style={{
            backgroundImage: `url(${bannerplanes})`,
            minHeight: "600px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column "
        >
          <h1 className="titleMain">
            NUESTROS PLANES Y MEMBRESÍAS TE BRINDAN
            <br />
            LA FLEXIBILIDAD DE TRABAJAR A TU RITMO
          </h1>
          <p
            className="EuclidCircularALight text-center text-white mx-2"
            style={{ color: "#3d3d3d" }}
          >
            Acceso a salas de juntas, áreas de coworking y espacios de oficina.
          </p>

          <div className="container d-flex justify-content-center flex-column flex-md-row">
            <div className="p-2">

            
            <a
                      className="link"
                      href="https://wa.me/3340089108"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
              <button className="bnt-white  mx-0 mx-md-4">Informes</button>
              </a>
            </div>
            <div className="p-2">
            <a
                      className="link"
                      href="http://www.enlacecoworking.com/"
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
              <button className="bnt-white  mx-0 mx-md-4">Visitar sitio web</button>
              </a>
            </div>
          </div>
        </div>
        <div className="w-100 p-5" /> 
        <Carrusel/>
        <div className="w-100 p-5" /> 
      </div>
    </motion.div>
  );
};

export default CoWorking;
