import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Head from "./includes/Head";
import Main from "./Main";
import Contacto from "./Contacto"
import Error404 from "./includes/404"
import FooterContacto from "./includes/FooterContacto"
import Nosotros from "./Nosotros";
import CoWorking from "./CoWorking";
import Proyectos from "./Proyectos";


const App = ()=> {

  const location =  useLocation();


  return (
    <div style={{backgroundColor:"black"}}> 
        <Head/> 
        <Routes location={location} key={location.key}> 
        <Route path="/" element={<Main />}></Route>
        <Route path="/contacto" element={<Contacto />} /> 
        <Route path="/nosotros" element={<Nosotros />} /> 
        <Route path="/proyectos" element={<Proyectos />} /> 
        <Route path="/coworking" element={<CoWorking />} /> 
        <Route path="*" element={<Error404 />} />
      </Routes>
      <FooterContacto/>
</div>
  );
}

export default App;
