import { postdData } from "./backend";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const SendCorreoContacto = async (values) => {
  let error = false;

  if (!values.nombre || values.nombre == "") {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Ingresa tu nombre",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  if (!values.correo || values.correo == "") {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Ingresa tu correo",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  let emailRegex = /^\S+@\S+\.\S+$/;
  if (!emailRegex.test(values.correo)) {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Tu correo es invalido",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  const phoneRegex = /^\d{10}$/;
  
  if (values.accion != "contacto2") {
    if (!values.telefono || values.telefono == "") {
      error = true;
      MySwal.fire({
        title: "Alerta",
        html: "Ingresa tu telefono",
        icon: "info",
        confirmButtonColor: "#34455e",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      }).then(() => {});
    }

   
    if (!phoneRegex.test(values.telefono)) {
      error = true;
      MySwal.fire({
        title: "Alerta",
        html: "Tu teléfono es invalido",
        icon: "info",
        confirmButtonColor: "#34455e",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      }).then(() => {});
    }
  }
  if (!values.whats || values.whats == "") {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Ingresa de WhatsApp",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  if (!phoneRegex.test(values.whats)) {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Tu teléfono de WhatsApp es invalido",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  if (!values.mensaje || values.mensaje == "") {
    error = true;
    MySwal.fire({
      title: "Alerta",
      html: "Ingresa algún mensaje para nosotros ",
      icon: "info",
      confirmButtonColor: "#34455e",
      showConfirmButton: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(() => {});
  }

  if (!error) {
    let data = [];
    const body = values;
    const res = await postdData("SendCorreo.php", body);
    return new Promise((resolve, reject) => {
      console.log(res);

      MySwal.fire({
        title: "¡Correcto!",
        html: res.data.mensaje,
        icon: "success",
        confirmButtonColor: "#34455e",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      }).then(() => {
         window.location.reload(true);
      });
      if (!res.error) {
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
export default SendCorreoContacto;
