import baner from "./img/Nosotros/banner/banner.jpg";
import logo from "./img/Main/Experiencias/AVIdesarrolladoralogo.png";
import icono1 from "./img/Nosotros/filosofía/icono-mision.png";
import icono3 from "./img/Nosotros/filosofía/icono-valores.png";
import icono2 from "./img/Nosotros/filosofía/icono-vision.png";

import imagen1 from "./img/Nosotros/asesoriasventas/foto-1.png";
import imagen2 from "./img/Nosotros/asesoriasventas/foto-2.png";
import fondoAsesorias from "./img/Nosotros/asesoriasventas/fondo.jpg";
import bener2 from "./img/Nosotros/mundoinmobiliario/fondo.jpg";
  
import { motion } from "framer-motion";

const Nosotros = () => {
  return (
    <motion.div
      initial={{ y: 0, opacity: 0.6 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container-fluid m-0 p-0">
        <div
          style={{
            backgroundImage: `url(${baner})`,
            minHeight: "700px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column"
        >
          <h1 className="titleMain mx-2">
            UNA EMPRESA MEXICADA DIRIGIENDO
            <br />
            EL RUMBO INMOBILIARIO
          </h1>
        </div>
      </div>
      <div className="container-gris-50 d-flex justify-content-center">
        <div className="container  container-gris mx-3">
          <h2 className="px-2 py-3  mb-0">
            Servicios confiables y profesionales
          </h2>
        </div>
      </div>

      <div className="container-fluid bg-white py-5">
        <div className="container mt-5 pt-3">
          <center>
            <img
              src={logo}
              className="img-fluid"
              style={{ maxWidth: "90px" }}
            />
          </center>

          <div className="d-flex justify-content-between my-4">
            <div className="lineGray align-self-center"></div>
            <div className="align-self-center">
              <div className="CircleGray"></div>
            </div>
            <div className="lineGray align-self-center"></div>
          </div>
        </div>

        <div className="container pb-5" style={{ maxWidth: "710px" }}>
          <h2 className="text-center CaviarDreams c-am mb-4">¿QUIÉNES SOMOS?</h2>
          <p className="justify-last-center EuclidCircularALight">
            Somos un despacho creado para dar soluciones a los desarrolaldores,
            inmobiliarios e inversionistas de bienes inmuebles y apoyarlos en
            optimizar los tiempos de respuesta con las diferentes instituciones
            y dependencias que intervienen en sus procesos para tener un
            proyecto  rentable.
          </p>
        </div>

        <div
          className="container-fluid bac-gray pt-5"
          style={{
            backgroundImage: `url(${fondoAsesorias})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container" style={{ maxWidth: "710px" }}>
            <h2 className="text-center CaviarDreams c-am">
              ASESORÍAS, VENTAS E INVERSIÓN
            </h2>
            <div className="d-flex justify-content-between my-4">
              <div className="lineGray align-self-center"></div>
              <div className="align-self-center">
                <div className="CircleGray"></div>
              </div>
              <div className="lineGray align-self-center"></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mt-4">
                  <div className="col-6 mt-5 pt-3">
                    <img src={imagen1} className="img-fluid" />
                  </div>
                  <div className="col-6">
                    <img src={imagen2} className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 pt-5">
                <center>
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{ maxWidth: "70px" }}
                  />
                </center>

                <p className=" text-justitfy  EuclidCircularALight justify mx-3 mx-md-5 mt-4">
                  Somos un despacho creado para dar soluciones a los
                  desarrolladores, inmobiliarios e inversionistas de bienes
                  inmuebles y apoyarlos en optimizar los tiempos de respuesta
                  con las diferentes instituciones y dependencias que
                  intervienen en sus procesos para tener un proyecto rentable.
                </p>
              </div>
            </div>
          </div>
          <div className="my-2 py-3"></div>
        </div>
        {/* Fin de fluid */}
      </div>

      <div className="container-fluid bg-white">
        <div className="container d-flex justify-content-center flex-wrap ">
          <div className="p-3" style={{maxWidth:"379px"}}>
            <div className="d-flex justify-content-center flex-column p-2 p-md-2">
              <img
                src={icono1}
                className="img-fluid mt-1 mx-auto"
                style={{ maxWidth: "55px" }}
              />
             <h2
            className="titleMain px-2 my-4 c-am"
            style={{ fontSize: "1.5rem" }}
          >
           MISIÓN
          </h2>
              <p className="EuclidCircularALight text-justitfy mx-3 mx-md-5" style={{color:"#000000"}}>
                Brindar un excelente servicio de asesoría inmobiliaria orientada a cumplir con las exigencias y necesidades del cliente.
                </p> 
            </div>
          </div>


          <div className="p-3" style={{maxWidth:"379px"}}>
            <div className="d-flex justify-content-center flex-column p-2 p-md-2">
              <img
                src={icono2}
                className="img-fluid mt-1 mx-auto"
                style={{ maxWidth: "55px" }}
              />
             <h2
            className="titleMain px-2 my-4 c-am"
            style={{ fontSize: "1.5rem" }}
          >
           VISIÓN
          </h2>
              <p className="EuclidCircularALight text-justitfy mx-3 mx-md-5" style={{color:"#000000"}}>
                CREAR, CRECER, SER, una comunidad de empresarios con una cultura de inovación y valores que aporten a cada socio, una visión diferente de los negocios.
                </p> 
            </div>
          </div>


          <div className="p-3 mb-5 mb-md-0" style={{maxWidth:"379px"}}>
            <div className="d-flex justify-content-center flex-column p-2 p-md-2">
              <img
                src={icono3}
                className="img-fluid mt-1 mx-auto"
                style={{ maxWidth: "55px" }}
              />
             <h2
            className="titleMain px-2 my-4 c-am"
            style={{ fontSize: "1.5rem" }}
          >
          VALORES
          </h2>
              <p className="EuclidCircularALight text-justitfy mx-3 mx-md-5" style={{color:"#000000"}}>
                Responsabilidad, Respeto. Ética, Gratitud, Solidad. Honestidad, Profesionalismo y Bondad.
                </p> 
            </div>
          </div>


        </div>
      </div>

      <div className="contaliner container-fluid p-0 m-0 bg-white ">
        <div
          style={{
            backgroundImage: `url(${bener2})`,
            minHeight: "699px",
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex justify-content-center flex-column content-fondo-sm"
        > 
          <h2
            className="titleMain px-2 titleMain-sm"
            style={{ fontSize: "calc(3rem + 1vw)" }}
          >
              DECIDIDOS A REVOLUCIONAR<br/>EL MUNDO INMOBILIARIO
          </h2>
        </div> 
        <div className="my-0 py-5"></div>
      </div>
    </motion.div>
  );
};

export default Nosotros;
